export function submitFormOnRadioButtonChange() {
  const radioButtonNodeList = document.querySelectorAll('.table-search-field [type="radio"]');
  Array.from(radioButtonNodeList).forEach((radioButton) => {
    radioButton.addEventListener("change", (event) => {
      event.target.form.submit();
    });
  });
}

export function resetSearchParams(event) {
  if (event.target.value.length > 0) {
    return;
  }

  event.preventDefault();

  // Remove search param from URL
  const url = new URL(window.location.href);
  url.searchParams.delete(event.target.name);
  window.location.href = url.toString();
}
