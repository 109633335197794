// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "./channels";

import * as Sentry from "@sentry/browser";

import "./jquery";
import "bootstrap";

import "@oddcamp/cocoon-vanilla-js";
import "select2";
import flatpickr from "flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

import { initTable } from "./components/tables";
import { getCookie } from "./util/get_cookie";
import { initUserSnap } from "./util/init_user_snap";
import { submitFormOnRadioButtonChange, resetSearchParams } from "./util/forms";
import { matchGroup } from "./util/match_group";

import * as ActiveStorage from "@rails/activestorage";
import Turbolinks from "turbolinks";
import Rails from "@rails/ujs";

if (window.config.enableErrorTracking) {
  Sentry.init({
    dsn: window.config.sentry.url,
    integrations: [Sentry.browserTracingIntegration()],
    environment: window.config.environment,
    release: window.config.release,
    tracesSampleRate: window.config.sentry_sample_rate,
  });
}

$(document).on("turbo:before-cache", function (e) {
  $(".date-single, .date-range").each(function () {
    return $(this).flatpickr("destroy");
  });

  $(".with-search, .search-match-group .with-clearable-search").each(function () {
    return $(this).select2("destroy");
  });
});

document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $.fn.select2.defaults.set("theme", "bootstrap4");

  $("select.with-search").select2();
  $("select.with-clearable-search").select2({
    theme: "bootstrap",
    allowClear: true,
    placeholder: {
      id: "",
      selected: "selected",
    },
  });

  flatpickr(".date-single", {
    altInput: true,
    altFormat: "d-m-Y",
    locale: Dutch,
  });

  const today = new Date();
  flatpickr(".date-range", {
    altInput: true,
    altFormat: "d-m-Y",
    locale: Dutch,
    mode: "range",
    onReady: function (selectedDates, dateStr, instance) {
      const cookie = getCookie("date_range");
      if (cookie) {
        instance.setDate(cookie);
      }
    },
    onClose: function (selectedDates, dateStr, instance) {
      // Only store date range if the user has selected two dates (a date range)
      if (dateStr.includes("t/m")) {
        document.cookie = `date_range=${dateStr}; path=/`;

        $(instance.element).closest("form").submit();
      }
    },
    defaultDate: [
      new Date(today.getFullYear(), today.getMonth(), 1),
      new Date(today.getFullYear(), today.getMonth() + 1, 0),
    ],
  });

  $(".with-search-group").select2({
    matcher: matchGroup,
  });

  if (window.config.enableUserSnap) {
    initUserSnap(document);
  }

  document.querySelectorAll("input[type=search]").forEach((searchInput) => {
    // Reset the search query if the search input is reset (on click of X)
    searchInput.addEventListener("search", resetSearchParams);
  });

  submitFormOnRadioButtonChange();
  initTable();
});

document.addEventListener("turbo:before-visit", (event) => {
  // Destroy Usersnap so it loads correctly with Turbolinks on the next visit
  if (!window.turbolinksVisitFlag) {
    event.preventDefault();
    if (window.Usersnap) {
      window.Usersnap.destroy();
    }
    window.turbolinksVisitFlag = true;
    setTimeout(() => {
      Turbo.visit(event.detail.url);
    }, 250);
  }
});

window.onUsersnapCXLoad = function (api) {
  window.Usersnap = api;
  api.init();
  window.turbolinksVisitFlag = false;
};

Rails.start();
Turbolinks.start();
ActiveStorage.start();
import "@hotwired/turbo-rails";
